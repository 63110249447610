import Cookies from "js-cookie";
import ENV from "../settings/env";

export const updateGoogleAnalyticsConsent = () => {
    const cookieConsentPreferences = Cookies.get("cookie-consent-preferences");

    if (cookieConsentPreferences == null) {
        return;
    }

    const parsedCookiePreferences = JSON.parse(cookieConsentPreferences);

    if (!parsedCookiePreferences?.performance) {
        (window as any)[`ga-disable-${ENV.googleAnalyticsId}`] = true;
    }
};
